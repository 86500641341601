import './bootstrap';

import { createApp, h } from 'vue';
import {createInertiaApp, usePage} from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import axios from 'axios';
import {Inertia} from '@inertiajs/inertia';
import PrimeVue from 'primevue/config';
import CaliPrimeVue from './presets/wind'

import 'primevue/resources/themes/saga-blue/theme.css'; //theme
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import VueApexCharts from 'vue3-apexcharts';
import 'chartjs-adapter-date-fns';
import ToastService from 'primevue/toastservice';
import { createPinia } from 'pinia'
import Tooltip from 'primevue/tooltip';

import '../css/app.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

const globalOptions = {
    mode: 'national',
    autoFormat: false,
    autoDefaultCountry: false,
    defaultCountry: 'cd',
    dynamicPlaceholder: true,
    dropdownOptions: {
        showSearchBox: true,
        showDialCodeInSelection: true,
    },
    validCharactersOnly: true,
    inputOptions: {
        placeholder: "88 88 88 888",
        showDialCode: false,
    },

    // Add other options here if needed
};
//import 'primeflex/primeflex.css';
// Import environment variables
const bugsnagApiKey = import.meta.env.VITE_BUGSNAG_API_KEY;
const releaseStage = import.meta.env.VITE_RELEASE_STAGE || 'production';
// Initialize Bugsnag
Bugsnag.start({
    apiKey: bugsnagApiKey,
    plugins: [new BugsnagPluginVue()],
    releaseStage: releaseStage, // Add releaseStage here
});
BugsnagPerformance.start({ apiKey: bugsnagApiKey });
// Initialize Bugsnag

// Get the Bugsnag Vue plugin
const bugsnagVue = Bugsnag.getPlugin('vue')



const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = import.meta.env.VITE_API_URL;
const pinia = createPinia()

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(bugsnagVue)
            .use(plugin)
            .use(PrimeVue, {
                unstyled: false,
                pt: CaliPrimeVue                            //apply preset
            })
            .use(ToastService)
            .use(ZiggyVue)
            .use(VueApexCharts)
            .use(pinia)
            .use(VueTelInput, globalOptions)
            .directive('tooltip', Tooltip)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});


// Axios Interceptor
axios.interceptors.response.use(
    response => response,
    error => {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        const page = usePage()
        const { auth } = page.props;
        const user = auth?.user;

        // Create a custom error message
        let errorDetails = {
            message: error.message,
            status: error.response?.status,
            data: error.response?.data,
            url: error.config?.url,
            method: error.config?.method,
        };

        // Send the error to Bugsnag with additional context
        if (error.response?.status !== 401) {
            Bugsnag.notify(new Error('Axios request failed'), (event) => {
                event.addMetadata('axios', errorDetails);
                // Set user details for the error in Bugsnag
                if (user) {
                    event.setUser(user.id, user.email, user.full_name);
                }
            });
        }

        return Promise.reject(error);
    }
);